.archive {
  .single-title {
    text-align: center;
    display: flex;

  }

  .group-title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  @import "../_partial/_archive/terms";
  @import "../_partial/_archive/tags";
}