html {
  font-family: var(--global-font-family);
  font-weight: var(--global-font-weight);
  font-display: swap;
  font-size: var(--global-font-size);
  line-height: var(--global-line-height);
  width: 100%;

  scroll-behavior: smooth;

  main [id] {
    scroll-margin-top: calc(var(--header-height) + .5rem);
  }

  h1 {
    line-height: 125%;
  }
}

/* scrollbar, only support webkit */
::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;

  &:hover {
    background-color: $scrollbar-hover-color;
  }
}

::selection {
  background-color: $selection-color;

  [theme=dark] & {
    background-color: $selection-color-dark;
  }
}

body {
  background-color: $global-background-color;
  color: $global-font-color;
  @include overflow-wrap(break-word);
  scrollbar-color: auto;

  &[theme=dark] {
    color: $global-font-color-dark;
    background-color: $global-background-color-dark;
  }
}

@include link(true, true);

@import "../_partial/mask";
@import "../_partial/img";
@import "../_partial/icon";
@import "../_partial/details";
@import "../_partial/fixed-button";
@import "../_partial/cookieconsent";