@charset "utf-8";

@import "_variables";
@import "_core/variables";

@import "_mixin/index";

@import "_core/base";
@import "_core/layout";

@import "_page/index";

@import "_core/header";
@import "_core/footer";
// @import "_core/pagination";
@import "_core/media";